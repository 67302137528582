<template>
  <section id="orders-analytics">
    <b-overlay :show="showOrdersIntakeAndFacturizedOrders" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-row>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalAmountOrdersIntakePrevYearValue | formatCurrencyNumber }} / {{
                    totalAmountOrdersIntakePrevYearYTDValue | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake previous year / YTD value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ totalAmountOrdersIntakeSelectedYearValue | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake this year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="ordersIntakeRelativeValue >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ ordersIntakeRelativeValue | formatNumber }} %
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ ordersIntakeRelativeValue | formatNumber }} %
                </h2>
                <span>Change YTD values</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ ordersIntakeTopMonth.turnover | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake Top Month is <b>{{ ordersIntakeTopMonth.month }}.{{ ordersIntakeTopMonth.year
                    }}</b></span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ ordersIntakeTargetQuote | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake total goal full year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ ordersIntakeTargetQuoteTillCurrentMonth | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake total goal until current month</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="ordersIntakeCummulatedDifference > 0" class="mb-25 font-weight-bolder text-danger">
                  {{ ordersIntakeCummulatedDifference | formatCurrencyNumber }}
                  <h6 class="font-weight-bolder text-danger">(behind our goal)</h6>
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-success">
                  {{ ordersIntakeCummulatedDifference | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake Cummulated Difference</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="ordersIntakeCummulatedDifference > 0" class="mb-25 font-weight-bolder text-danger">
                  {{ (ordersIntakeCummulatedDifference / (13 - currentMonthNumber)) | formatCurrencyNumber }}
                  <h6 class="font-weight-bolder text-danger">(behind our goal)</h6>
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-success">
                  {{ (ordersIntakeCummulatedDifference / (13 - currentMonthNumber)) | formatCurrencyNumber }}
                </h2>
                <span>Orders Intake Cummulated Difference seperated on the rest of the year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Orders intake
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-intake" />
          </h4>
          <b-popover target="popover-orders-intake" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="ordersIntakeChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative" :current-page="ordersIntakeTable.currentPage"
            :per-page="10" :items="ordersIntakeTable.items" :fields="ordersIntakeTable.fields"
            :sort-by.sync="ordersIntakeTable.sortBy" :sort-desc.sync="ordersIntakeTable.sortDesc"
            :sort-direction="ordersIntakeTable.sortDirection" :filter="ordersIntakeTable.filter"
            :filter-included-fields="ordersIntakeTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Orders intake by country group
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-orders-intake-country-group" />
          </h4>
          <b-popover target="popover-orders-intake-country-group" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="ordersIntakeByCountryGroupChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="ordersIntakeByCountryGroupTable.currentPage" :per-page="10"
            :items="ordersIntakeByCountryGroupTable.items" :fields="ordersIntakeByCountryGroupTable.fields"
            :sort-by.sync="ordersIntakeByCountryGroupTable.sortBy"
            :sort-desc.sync="ordersIntakeByCountryGroupTable.sortDesc"
            :sort-direction="ordersIntakeByCountryGroupTable.sortDirection"
            :filter="ordersIntakeByCountryGroupTable.filter"
            :filter-included-fields="ordersIntakeByCountryGroupTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="6">
          <b-row v-for="(platform, index) in ['Amazon', 'Onlineshop', 'JTL-Wawi']" :key="index">
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ totalOrdersIntakeAmountsByPlatformPrevYear[platform] | formatCurrencyNumber }} / {{
                        totalOrdersIntakeAmountsByPlatformYTDValue[platform] | formatCurrencyNumber }}
                    </h2>
                    <span>{{ platform }} amount previous year / YTD value</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder text-info">
                      {{ totalOrdersIntakeAmountsByPlatformThisYear[platform] | formatCurrencyNumber }}
                    </h2>
                    <span>{{ platform }} amount this year</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2
                      v-if="displayRelativeValue(totalOrdersIntakeAmountsByPlatformYTDValue[platform], totalOrdersIntakeAmountsByPlatformThisYear[platform]) >= 0"
                      class="mb-25 font-weight-bolder text-success">
                      +{{ displayRelativeValue(totalOrdersIntakeAmountsByPlatformYTDValue[platform],
                        totalOrdersIntakeAmountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <h2 v-else class="mb-25 font-weight-bolder text-danger">
                      {{ displayRelativeValue(totalOrdersIntakeAmountsByPlatformYTDValue[platform],
                        totalOrdersIntakeAmountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <span>Change YTD values</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="6">
          <b-row v-for="(platform, index) in ['Amazon', 'Onlineshop', 'JTL-Wawi']" :key="index">
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ totalOrdersIntakeCountsByPlatformPrevYear[platform] | formatNumber }} / {{
                        totalOrdersIntakeCountsByPlatformYTDValue[platform] | formatNumber }}
                    </h2>
                    <span>{{ platform }} count previous year / YTD value</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ totalOrdersIntakeCountsByPlatformThisYear[platform] | formatNumber }}
                    </h2>
                    <span>{{ platform }} count this year</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2
                      v-if="displayRelativeValue(totalOrdersIntakeCountsByPlatformYTDValue[platform], totalOrdersIntakeCountsByPlatformThisYear[platform]) >= 0"
                      class="mb-25 font-weight-bolder text-success">
                      +{{ displayRelativeValue(totalOrdersIntakeCountsByPlatformYTDValue[platform],
                        totalOrdersIntakeCountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <h2 v-else class="mb-25 font-weight-bolder text-danger">
                      {{ displayRelativeValue(totalOrdersIntakeCountsByPlatformYTDValue[platform],
                        totalOrdersIntakeCountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <span>Change YTD values</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Orders intake by platform amount
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-orders-platform-amount" />
          </h4>
          <b-popover target="popover-orders-platform-amount" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="ordersIntakeByPlatformAmountChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="ordersIntakeByPlatformAmountTable.currentPage" :per-page="3"
            :items="ordersIntakeByPlatformAmountTable.items" :fields="ordersIntakeByPlatformAmountTable.fields"
            :sort-by.sync="ordersIntakeByPlatformAmountTable.sortBy"
            :sort-desc.sync="ordersIntakeByPlatformAmountTable.sortDesc"
            :sort-direction="ordersIntakeByPlatformAmountTable.sortDirection"
            :filter="ordersIntakeByPlatformAmountTable.filter"
            :filter-included-fields="ordersIntakeByPlatformAmountTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Orders intake by platform count
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-orders-platform-count" />
          </h4>
          <b-popover target="popover-orders-platform-count" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="ordersIntakeByPlatformCountChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="ordersIntakeByPlatformCountTable.currentPage" :per-page="3"
            :items="ordersIntakeByPlatformCountTable.items" :fields="ordersIntakeByPlatformCountTable.fields"
            :sort-by.sync="ordersIntakeByPlatformCountTable.sortBy"
            :sort-desc.sync="ordersIntakeByPlatformCountTable.sortDesc"
            :sort-direction="ordersIntakeByPlatformCountTable.sortDirection"
            :filter="ordersIntakeByPlatformCountTable.filter"
            :filter-included-fields="ordersIntakeByPlatformCountTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalAmountFacturizedOrdersPrevYearValue | formatCurrencyNumber }} / {{
                    totalAmountFacturizedOrdersPrevYearYTDValue | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders previous year / YTD value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ totalAmountFacturizedOrdersSelectedYearValue | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders this year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="facturizedOrdersRelativeValue >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ facturizedOrdersRelativeValue | formatNumber }} %
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ facturizedOrdersRelativeValue | formatNumber }} %
                </h2>
                <span>Change YTD values</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ facturizedOrdersTopMonth.turnover | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders Top Month is <b>{{ facturizedOrdersTopMonth.month }}.{{
                  facturizedOrdersTopMonth.year }}</b></span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ facturizedOrdersTargetQuote | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders total goal full year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ facturizedOrdersTargetQuoteTillCurrentMonth | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders total goal until current month</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="facturizedOrdersCummulatedDifference > 0" class="mb-25 font-weight-bolder text-danger">
                  {{ facturizedOrdersCummulatedDifference | formatCurrencyNumber }}
                  <h6 class="font-weight-bolder text-danger">(behind our goal)</h6>
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-success">
                  {{ facturizedOrdersCummulatedDifference | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders Cummulated Difference</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="facturizedOrdersCummulatedDifference > 0" class="mb-25 font-weight-bolder text-danger">
                  {{ (facturizedOrdersCummulatedDifference / (13 - currentMonthNumber)) | formatCurrencyNumber }}
                  <h6 class="font-weight-bolder text-danger">(behind our goal)</h6>
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-success">
                  {{ (facturizedOrdersCummulatedDifference / (13 - currentMonthNumber)) | formatCurrencyNumber }}
                </h2>
                <span>Facturized Orders Cummulated Difference seperated on the rest of the year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Facturized orders amount
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-facturized-orders-amount" />
          </h4>
          <b-popover target="popover-facturized-orders-amount" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="facturizedOrdersChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative" :current-page="facturizedOrdersTable.currentPage"
            :per-page="10" :items="facturizedOrdersTable.items" :fields="facturizedOrdersTable.fields"
            :sort-by.sync="facturizedOrdersTable.sortBy" :sort-desc.sync="facturizedOrdersTable.sortDesc"
            :sort-direction="facturizedOrdersTable.sortDirection" :filter="facturizedOrdersTable.filter"
            :filter-included-fields="facturizedOrdersTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Facturized orders amount by country group
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-facturized-orders-amount-country-group" />
          </h4>
          <b-popover target="popover-facturized-orders-amount-country-group" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="facturizedOrdersByCountryGroupChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="facturizedOrdersByCountryGroupTable.currentPage" :per-page="10"
            :items="facturizedOrdersByCountryGroupTable.items" :fields="facturizedOrdersByCountryGroupTable.fields"
            :sort-by.sync="facturizedOrdersByCountryGroupTable.sortBy"
            :sort-desc.sync="facturizedOrdersByCountryGroupTable.sortDesc"
            :sort-direction="facturizedOrdersByCountryGroupTable.sortDirection"
            :filter="facturizedOrdersByCountryGroupTable.filter"
            :filter-included-fields="facturizedOrdersByCountryGroupTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="6">
          <b-row v-for="(platform, index) in ['Amazon', 'Onlineshop', 'JTL-Wawi']" :key="index">
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ totalAmountsByPlatformPrevYear[platform] | formatCurrencyNumber }} / {{
                        totalAmountsByPlatformYTDValue[platform] | formatCurrencyNumber }}
                    </h2>
                    <span>{{ platform }} amount previous year / YTD value</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder text-info">
                      {{ totalAmountsByPlatformThisYear[platform] | formatCurrencyNumber }}
                    </h2>
                    <span>{{ platform }} amount this year</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2
                      v-if="displayRelativeValue(totalAmountsByPlatformYTDValue[platform], totalAmountsByPlatformThisYear[platform]) >= 0"
                      class="mb-25 font-weight-bolder text-success">
                      +{{ displayRelativeValue(totalAmountsByPlatformYTDValue[platform],
                        totalAmountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <h2 v-else class="mb-25 font-weight-bolder text-danger">
                      {{ displayRelativeValue(totalAmountsByPlatformYTDValue[platform],
                        totalAmountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <span>Change YTD values</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="6">
          <b-row v-for="(platform, index) in ['Amazon', 'Onlineshop', 'JTL-Wawi']" :key="index">
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ totalCountsByPlatformPrevYear[platform] | formatNumber }} / {{
                        totalCountsByPlatformYTDValue[platform] | formatNumber }}
                    </h2>
                    <span>{{ platform }} count previous year / YTD value</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ totalCountsByPlatformThisYear[platform] | formatNumber }}
                    </h2>
                    <span>{{ platform }} count this year</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="4" md="4">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2
                      v-if="displayRelativeValue(totalCountsByPlatformYTDValue[platform], totalCountsByPlatformThisYear[platform]) >= 0"
                      class="mb-25 font-weight-bolder text-success">
                      +{{ displayRelativeValue(totalCountsByPlatformYTDValue[platform],
                        totalCountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <h2 v-else class="mb-25 font-weight-bolder text-danger">
                      {{ displayRelativeValue(totalCountsByPlatformYTDValue[platform],
                        totalCountsByPlatformThisYear[platform]) | formatNumber }} %
                    </h2>
                    <span>Change YTD values</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Facturized orders by platform amount
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-orders-platform-amount" />
          </h4>
          <b-popover target="popover-orders-platform-amount" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="ordersByPlatformAmountChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="ordersByPlatformAmountTable.currentPage" :per-page="10"
            :items="ordersByPlatformAmountTable.items" :fields="ordersByPlatformAmountTable.fields"
            :sort-by.sync="ordersByPlatformAmountTable.sortBy" :sort-desc.sync="ordersByPlatformAmountTable.sortDesc"
            :sort-direction="ordersByPlatformAmountTable.sortDirection" :filter="ordersByPlatformAmountTable.filter"
            :filter-included-fields="ordersByPlatformAmountTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ ordersCountPrevYearValue | formatNumber }} / {{ ordersCountPrevYearYTDValue | formatNumber }}
                </h2>
                <span>Orders count previous year / YTD value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ ordersCountSelectedYearValue | formatNumber }}
                </h2>
                <span>Orders count this year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="ordersCountRelativeValue >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ ordersCountRelativeValue | formatNumber }} %
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ ordersCountRelativeValue | formatNumber }} %
                </h2>
                <span>Change YTD values</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Facturized orders by platform count
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-orders-platform-count" />
          </h4>
          <b-popover target="popover-orders-platform-count" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="ordersByPlatformCountChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="ordersByPlatformCountTable.currentPage" :per-page="10"
            :items="ordersByPlatformCountTable.items" :fields="ordersByPlatformCountTable.fields"
            :sort-by.sync="ordersByPlatformCountTable.sortBy" :sort-desc.sync="ordersByPlatformCountTable.sortDesc"
            :sort-direction="ordersByPlatformCountTable.sortDirection" :filter="ordersByPlatformCountTable.filter"
            :filter-included-fields="ordersByPlatformCountTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders intake by years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-intake-by-years" />
              </h4>
              <b-popover target="popover-orders-intake-by-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersIntakeByYearsChart" :options="ordersIntakeByYearsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Facturized orders by years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-facturized-orders-by-years" />
              </h4>
              <b-popover target="popover-facturized-orders-by-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="facturizedOrdersByYearsChart" :options="facturizedOrdersByYearsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ offersAmountPrevYearValue | formatCurrencyNumber }} / {{ offersAmountPrevYearYTDValue |
                    formatCurrencyNumber }}
                </h2>
                <span>Offers amount previous year / YTD value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ offersAmountSelectedYearValue | formatCurrencyNumber }}
                </h2>
                <span>Offers amount this year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="offersAmountRelativeValue >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ offersAmountRelativeValue | formatNumber }} %
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ offersAmountRelativeValue | formatNumber }} %
                </h2>
                <span>Change YTD values</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Offers amount
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offers-amount" />
          </h4>
          <b-popover target="popover-offers-amount" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="offersAmountChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative" :current-page="offersAmountTable.currentPage"
            :per-page="10" :items="offersAmountTable.items" :fields="offersAmountTable.fields"
            :sort-by.sync="offersAmountTable.sortBy" :sort-desc.sync="offersAmountTable.sortDesc"
            :sort-direction="offersAmountTable.sortDirection" :filter="offersAmountTable.filter"
            :filter-included-fields="offersAmountTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Offers amount by country group
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-offers-amount-country-group" />
          </h4>
          <b-popover target="popover-offers-amount-country-group" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="offersAmountByCountryGroupChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="offersAmountByCountryGroupTable.currentPage" :per-page="10"
            :items="offersAmountByCountryGroupTable.items" :fields="offersAmountByCountryGroupTable.fields"
            :sort-by.sync="offersAmountByCountryGroupTable.sortBy"
            :sort-desc.sync="offersAmountByCountryGroupTable.sortDesc"
            :sort-direction="offersAmountByCountryGroupTable.sortDirection"
            :filter="offersAmountByCountryGroupTable.filter"
            :filter-included-fields="offersAmountByCountryGroupTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ offersCountPrevYearValue | formatNumber }} / {{ offersCountPrevYearYTDValue | formatNumber }}
                </h2>
                <span>Offers count previous year / YTD value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ offersCountSelectedYearValue | formatNumber }}
                </h2>
                <span>Offers count this year</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 v-if="offersCountRelativeValue >= 0" class="mb-25 font-weight-bolder text-success">
                  +{{ offersCountRelativeValue | formatNumber }} %
                </h2>
                <h2 v-else class="mb-25 font-weight-bolder text-danger">
                  {{ offersCountRelativeValue | formatNumber }} %
                </h2>
                <span>Change YTD values</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Offers count by country group
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-offers-count-country-group" />
          </h4>
          <b-popover target="popover-offers-count-country-group" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts :options="offersCountByCountryGroupChart" style="width: 100%; height: 500px;" />
        <b-card no-body>
          <b-table striped hover responsive class="position-relative"
            :current-page="offersCountByCountryGroupTable.currentPage" :per-page="10"
            :items="offersCountByCountryGroupTable.items" :fields="offersCountByCountryGroupTable.fields"
            :sort-by.sync="offersCountByCountryGroupTable.sortBy"
            :sort-desc.sync="offersCountByCountryGroupTable.sortDesc"
            :sort-direction="offersCountByCountryGroupTable.sortDirection"
            :filter="offersCountByCountryGroupTable.filter"
            :filter-included-fields="offersCountByCountryGroupTable.filterOn">
          </b-table>
        </b-card>
      </b-card>
    </b-overlay>
    <b-row>
      <b-col xl="4" md="4">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ totalAmountOpenOrders | formatCurrencyNumber }}
              </h2>
              <span>Amount {{ totalAmountOpenOrdersTitle }}</span>
            </div>
            <b-overlay :show="showOpenOrdersDwnIcon" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <div @click="exportOpenOrders">
                <b-avatar variant="light-secondary" size="45">
                  <feather-icon size="21" icon="DownloadIcon" />
                </b-avatar>
              </div>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-info">
                {{ totalAmountPaidOpenOrders | formatCurrencyNumber }}
              </h2>
              <span>Amount paid open orders</span>
            </div>
            <b-overlay :show="showPaidOpenOrdersDwnIcon" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <div @click="exportPaidOpenOrders">
                <b-avatar variant="light-secondary" size="45">
                  <feather-icon size="21" icon="DownloadIcon" />
                </b-avatar>
              </div>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-danger">
                {{ totalAmountOverdueOrders | formatCurrencyNumber }}
              </h2>
              <span>Amount overdue</span>
            </div>
            <b-overlay :show="showOverdueOrdersDwnIcon" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <div @click="exportOverdueOrders">
                <b-avatar variant="light-secondary" size="45">
                  <feather-icon size="21" icon="DownloadIcon" />
                </b-avatar>
              </div>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="4" md="4">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-warning">
                {{ totalAmountNotPaidInvoices | formatCurrencyNumber }}
              </h2>
              <span>Amount need to send to suppliers</span>
            </div>
            <b-overlay :show="showNotPaidInvoicesDwnIcon" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <div @click="exportNotPaidInvoices">
                <b-avatar variant="light-secondary" size="45">
                  <feather-icon size="21" icon="DownloadIcon" />
                </b-avatar>
              </div>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder text-success">
                {{ totalAmountExpIn | formatCurrencyNumber }}
              </h2>
              <span>Amount must be received</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="4" md="4">
        <b-card no-body>
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 v-if="totalLiquidity >= 0" class="mb-25 font-weight-bolder text-success">
                +{{ totalLiquidity | formatCurrencyNumber }}
              </h2>
              <h2 v-else class="mb-25 font-weight-bolder text-danger">
                {{ totalLiquidity | formatCurrencyNumber }}
              </h2>
              <span>Liquidity</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Development open orders
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-development-open-orders" />
            </h4>
            <b-popover target="popover-development-open-orders" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <ECharts :options="openOrdersAmountsChart" style="width: 100%; height: 400px;" />
          <b-card no-body>
            <b-table striped hover responsive class="position-relative"
              :current-page="openOrdersAmountsTable.currentPage" :per-page="10" :items="openOrdersAmountsTable.items"
              :fields="openOrdersAmountsTable.fields" :sort-by.sync="openOrdersAmountsTable.sortBy"
              :sort-desc.sync="openOrdersAmountsTable.sortDesc" :sort-direction="openOrdersAmountsTable.sortDirection"
              :filter="openOrdersAmountsTable.filter" :filter-included-fields="openOrdersAmountsTable.filterOn">
            </b-table>
          </b-card>
        </b-card>
      </b-col>
      <b-col xl="6">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Open orders
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-open-orders" />
            </h4>
            <b-popover target="popover-open-orders" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <ECharts :options="openOrdersChart" style="width: 100%; height: 400px;" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Open orders by platform
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-open-orders-platform" />
            </h4>
            <b-popover target="popover-open-orders-platform" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <ECharts :options="openOrdersPlatformChart" style="width: 100%; height: 400px;" />
        </b-card>
      </b-col>
      <b-col xl="6">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Open orders by country
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-open-orders-country" />
            </h4>
            <b-popover target="popover-open-orders-country" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <ECharts :options="openOrdersCountryChart" style="width: 100%; height: 400px;" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BTable,
  BPagination,
  BPopover,
  BOverlay,
  BAvatar,
} from 'bootstrap-vue';
import axios from 'axios';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const currentMonthNumber = new Date().getMonth() + 1;
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const countryGroups = ['Outside EU', 'Germany', 'EU (except Germany)'];
const platforms = ['Amazon', 'Onlineshop', 'JTL-Wawi'];

function orderTooltipCountryGroups(result) {
  // Split the result into individual lines
  let items = result.split('<br/>').filter(item => item.trim() !== "");

  // Define a custom order for each category
  const order = [
    "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
    "Germany-2023", "Germany-2024", "Germany-2023 MTD",
    "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
  ];

  // Custom sort function based on our order array
  items.sort((a, b) => {
    const aIndex = order.findIndex(key => a.includes(key));
    const bIndex = order.findIndex(key => b.includes(key));
    return aIndex - bIndex;
  });

  // Reassemble ordered items back into the string with '<br/>'
  return items.join('<br/>');
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BTable,
    BPagination,
    BPopover,
    BOverlay,
    BAvatar,
    ECharts,
  },
  data() {
    return {
      showOrdersIntakeAndFacturizedOrders: true,
      showOffersByMonth: true,
      showOrdersByPlatformMonth: true,
      showOpenOrdersAnalytics: true,
      showOpenOrdersDwnIcon: false,
      showOverdueOrdersDwnIcon: false,
      showPaidOpenOrdersDwnIcon: false,
      showNotPaidInvoicesDwnIcon: false,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      totalAmountOrdersIntakePrevYearValue: 0.0,
      totalAmountOrdersIntakeSelectedYearValue: 0.0,
      totalAmountFacturizedOrdersPrevYearValue: 0.0,
      totalAmountFacturizedOrdersSelectedYearValue: 0.0,
      totalAmountOrdersIntakePrevYearYTDValue: 0.0,
      ordersIntakeRelativeValue: 0.0,
      totalAmountFacturizedOrdersPrevYearYTDValue: 0.0,
      facturizedOrdersRelativeValue: 0.0,
      offersAmountPrevYearValue: 0.0,
      offersAmountSelectedYearValue: 0.0,
      offersAmountRelativeValue: 0.0,
      offersAmountPrevYearYTDValue: 0.0,
      ordersCountPrevYearValue: 0.0,
      ordersCountSelectedYearValue: 0.0,
      ordersCountRelativeValue: 0.0,
      ordersCountPrevYearYTDValue: 0.0,
      offersCountPrevYearValue: 0.0,
      offersCountSelectedYearValue: 0.0,
      offersCountRelativeValue: 0.0,
      offersCountPrevYearYTDValue: 0.0,
      totalAmountsByPlatformPrevYear: {},
      totalOrdersIntakeAmountsByPlatformPrevYear: {},
      totalAmountsByPlatformThisYear: {},
      totalOrdersIntakeAmountsByPlatformThisYear: {},
      totalCountsByPlatformPrevYear: {},
      totalOrdersIntakeCountsByPlatformPrevYear: {},
      totalCountsByPlatformThisYear: {},
      totalOrdersIntakeCountsByPlatformThisYear: {},
      totalAmountsByPlatformYTDValue: 0.0,
      totalOrdersIntakeAmountsByPlatformYTDValue: 0.0,
      totalCountsByPlatformYTDValue: 0.0,
      totalOrdersIntakeCountsByPlatformYTDValue: 0.0,
      ordersIntakeTargetQuote: 0.0,
      ordersIntakeTargetQuoteTillCurrentMonth: 0.0,
      ordersIntakeCummulatedDifference: 0.0,
      currentMonthNumber: currentMonthNumber,
      facturizedOrdersTargetQuote: 0.0,
      facturizedOrdersTargetQuoteTillCurrentMonth: 0.0,
      facturizedOrdersCummulatedDifference: 0.0,
      totalAmountOpenOrders: 0.0,
      totalAmountOpenOrdersTitle: '',
      totalAmountPaidOpenOrders: 0.0,
      totalAmountOverdueOrders: 0.0,
      totalAmountNotPaidInvoices: 0.0,
      totalAmountExpIn: 0.0,
      totalLiquidity: 0.0,
      ordersIntakeTopMonth: {},
      facturizedOrdersTopMonth: {},
      queryParamsOpenOrders: {},
      ordersIntakeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersIntakeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersIntakeByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersIntakeByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      facturizedOrdersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      facturizedOrdersByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      facturizedOrdersCountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersCountTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersIntakeByYearsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersByYearsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersAmountTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      offersAmountByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersAmountByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      offersCountByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersCountByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersByPlatformAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersByPlatformAmountTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersIntakeByPlatformAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersIntakeByPlatformAmountTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersByPlatformCountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersByPlatformCountTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersIntakeByPlatformCountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersIntakeByPlatformCountTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      openOrdersAmountsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      openOrdersAmountsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      openOrdersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      openOrdersPlatformChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      openOrdersCountryChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
    }
  },
  async created() {
    try {
      await this.getOrdersIntakeAndFacturizedOrders();
      await this.getOffersByMonth();
      await this.getOrdersByPlatformMonth();
      await this.getOpenOrders();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    getFromToDates() {
      const qParamsSelectedYear = {};
      const qParamsPrevYear = {};
      const dateRangeArr = this.dateRange.split(' to ');

      let fromDateSelectedYear;
      let toDateSelectedYear;

      // Check if the year of the start date is less than the current year
      if (new Date(dateRangeArr[0]).getFullYear() < new Date().getFullYear()) {
        fromDateSelectedYear = `${new Date(dateRangeArr[1]).getFullYear()}-01-01`;
      } else {
        fromDateSelectedYear = dateRangeArr[0];
      }
      toDateSelectedYear = dateRangeArr[1];

      const fromDateSelectedYearDate = new Date(fromDateSelectedYear);
      const toDateSelectedYearDate = new Date(toDateSelectedYear);
      const fromDatePrevYearDate = new Date(fromDateSelectedYearDate.getFullYear() - 1, fromDateSelectedYearDate.getMonth(), fromDateSelectedYearDate.getDate());
      const toDatePrevYearDate = new Date(toDateSelectedYearDate.getFullYear() - 1, 11, 31); // Fixed to ensure end of the year

      // Format dates
      const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

      qParamsSelectedYear.from_date = formatDate(fromDateSelectedYearDate);
      qParamsSelectedYear.to_date = formatDate(toDateSelectedYearDate);
      qParamsPrevYear.from_date = formatDate(fromDatePrevYearDate);
      qParamsPrevYear.to_date = formatDate(toDatePrevYearDate);

      return { qParamsSelectedYear, qParamsPrevYear };
    },
    processDataOrdersByCountryGroup(data, value) {
      const year = data[0].year;
      const months = Array.from({ length: 12 }, (_, i) => i + 1);
      const country_groups = [...new Set(data.map(item => item.country_group))];
      const monthlyData = {};
      const ytdData = {};

      country_groups.sort((a, b) => a.localeCompare(b));

      country_groups.forEach(country_group => {
        monthlyData[country_group] = months.map(month =>
          data
            .filter(item => item.year === year && item.month === month && item.country_group === country_group)
            .reduce((sum, current) => sum + current[value], 0)
        );
        ytdData[country_group] = monthlyData[country_group].slice(0, currentMonthNumber).reduce((sum, current) => sum + current, 0);
      });

      return { year, monthlyData, ytdData };
    },
    formatMonthYear(month, year) {
      return `${monthNames[month - 1]}.${year}`
    },
    transformData(arr) {
      return arr.map(item => {
        const result = { label: item.name };
        item.data.forEach((value, index) => {
          result[monthNames[index]] = value.toLocaleString('de-DE');
        });
        return result;
      });
    },
    convertToFloat(value) {
      let formattedValue = value.replace(/\./g, '');
      formattedValue = formattedValue.replace(',', '.');
      return parseFloat(formattedValue);
    },
    findEntryByLabel(data, label) {
      return data.find(entry => entry.label === label);
    },
    createComparisonRow(label, baseEntry, comparisonEntry) {
      const row = { label };
      monthNames.forEach((month, index) => {
        if (index < currentMonthNumber) {
          row[month] = (baseEntry && comparisonEntry)
            ? (this.convertToFloat(baseEntry[month]) - this.convertToFloat(comparisonEntry[month])).toLocaleString('de-DE')
            : 0;
        } else {
          row[month] = 0;
        }
      });
      return row;
    },
    addComparisonRows(data) {
      const currentYearEntry = this.findEntryByLabel(data, currentDate.getFullYear());
      const previousYearEntry = this.findEntryByLabel(data, currentDate.getFullYear() - 1);
      const planNumbersEntry = this.findEntryByLabel(data, `${currentDate.getFullYear()} Plan Numbers`);

      const comparisonToPlanNumbers = this.createComparisonRow('Comparison to Plan Numbers', currentYearEntry, planNumbersEntry);
      const comparisonToPrevYear = this.createComparisonRow('Comparison to Prev. Year', currentYearEntry, previousYearEntry);

      return [...data, comparisonToPlanNumbers, comparisonToPrevYear];
    },
    transformDataByCountryGroup(arr, val) {
      const resultMap = new Map();

      arr.forEach(item => {
        const key = this.formatMonthYear(item.month, item.year);
        if (!resultMap.has(key)) {
          resultMap.set(key, { month: item.month, year: item.year, 'Outside EU': 0, 'Germany': 0, 'EU (except Germany)': 0 });
        }
        resultMap.get(key)[item.country_group] = (val === 'turnover' || val === 'offers_value') ? item[val].toLocaleString('de-DE') : item[val];
      });

      return Array.from(resultMap.entries()).map(([key, value]) => ({
        key,
        ...value
      }));
    },
    transformDataByPlatform(arr, val) {
      const resultMap = new Map();

      arr.forEach(item => {
        const key = this.formatMonthYear(item.month, item.year);
        if (!resultMap.has(key)) {
          resultMap.set(key, { month: item.month, year: item.year, 'Amazon': 0, 'Onlineshop': 0, 'JTL-Wawi': 0 });
        }
        resultMap.get(key)[item.platform] = (val === 'turnover') ? item[val].toLocaleString('de-DE') : item[val];
      });

      return Array.from(resultMap.entries()).map(([key, value]) => ({
        key,
        ...value
      }));
    },
    transformOpenOrdersData(arr) {
      const resultMap = new Map();

      arr.forEach(item => {
        const date = new Date(item.created);
        const year = date.getFullYear();
        const month = monthNames[date.getMonth()];

        if (!resultMap.has(year)) {
          resultMap.set(year, { label: year });
          monthNames.forEach(month => {
            resultMap.get(year)[month] = 0;
          });
        }

        resultMap.get(year)[month] = parseFloat(item.amount).toLocaleString('de-DE');
      });

      return Array.from(resultMap.values());
    },
    updateOrdersIntakeAndFacturizedOrders(data) {
      const newData0 = [];
      const newData1 = [];
      const newData2 = [];
      const newData3 = [];
      const newData4OI = [];
      const newData4FO = [];
      const newData11 = [];
      const newData12 = [];

      const data0 = data[0].data.results;
      const data1 = data[1].data.results;
      const data2 = data[2].data.results;
      const data3 = data[3].data.results;
      const data4 = data[4].data.results;
      const data11 = data[11].data.results;
      const data12 = data[12].data.results;

      for (var i = 1; i < 13; i++) {
        for (var j = 0; j < data0.length; j++) {
          if (i === data0[j].month) {
            newData0.push(data0[j]);
            if (data0[j].month < currentMonthNumber) {
              this.totalAmountOrdersIntakePrevYearYTDValue += data0[j].turnover;
            }
            this.totalAmountOrdersIntakePrevYearValue += data0[j].turnover;
            break;
          }
        }
        if (!newData0[i - 1]) {
          newData0.push({ turnover: 0.0, month: i, year: data0[0].year });
        }

        for (j = 0; j < data2.length; j++) {
          if (i === data2[j].month) {
            if (data2[j].month < currentMonthNumber) {
              this.ordersIntakeCummulatedDifference += data4.oi[j].turnover - data2[j].turnover;
            }
            newData2.push(data2[j]);
            this.totalAmountOrdersIntakeSelectedYearValue += data2[j].turnover;
            break;
          }
        }
        if (!newData2[i - 1]) {
          newData2.push({ turnover: 0.0, month: i, year: data2[0].year });
        }

        for (j = 0; j < data1.length; j++) {
          if (i === data1[j].month) {
            newData1.push(data1[j]);
            if (data1[j].month < currentMonthNumber) {
              this.totalAmountFacturizedOrdersPrevYearYTDValue += data1[j].turnover;
              this.ordersCountPrevYearYTDValue += data1[j].orders_count;
            }
            this.totalAmountFacturizedOrdersPrevYearValue += data1[j].turnover;
            this.ordersCountPrevYearValue += data1[j].orders_count;
            break;
          }
        }
        if (!newData1[i - 1]) {
          newData1.push({ turnover: 0.0, orders_count: 0, month: i, year: data1[0].year });
        }

        for (j = 0; j < data3.length; j++) {
          if (i === data3[j].month) {
            if (data3[j].month < currentMonthNumber) {
              this.facturizedOrdersCummulatedDifference += data4.fo[j].turnover - data3[j].turnover;
            }
            newData3.push(data3[j]);
            this.totalAmountFacturizedOrdersSelectedYearValue += data3[j].turnover;
            this.ordersCountSelectedYearValue += data3[j].orders_count;
            break;
          }
        }
        if (!newData3[i - 1]) {
          newData3.push({ turnover: 0.0, orders_count: 0, month: i, year: data3[0].year });
        }

        // Plan numbers
        for (j = 0; j < data4.fo.length; j++) {
          if (i === data4.fo[j].month) {
            newData4FO.push(data4.fo[j]);
            this.facturizedOrdersTargetQuote += data4.fo[j].turnover;
            if (data4.fo[j].month <= currentMonthNumber) {
              this.facturizedOrdersTargetQuoteTillCurrentMonth += data4.fo[j].turnover;
            }
            break;
          }
        }
        if (!newData4FO[i - 1]) {
          newData4FO.push({ turnover: 0.0, month: i, year: data4.fo[0].year });
        }

        for (j = 0; j < data4.oi.length; j++) {
          if (i === data4.oi[j].month) {
            newData4OI.push(data4.oi[j]);
            this.ordersIntakeTargetQuote += data4.oi[j].turnover;
            if (data4.oi[j].month <= currentMonthNumber) {
              this.ordersIntakeTargetQuoteTillCurrentMonth += data4.oi[j].turnover;
            }
            break;
          }
        }
        if (!newData4OI[i - 1]) {
          newData4OI.push({ turnover: 0.0, month: i, year: data4.oi[0].year });
        }

        if (data.length === 17) {
          for (j = 0; j < data11.length; j++) {
            if (i === data11[j].month) {
              newData11.push(data11[j]);
              this.totalAmountOrdersIntakePrevYearYTDValue += data11[j].turnover;
              break;
            }
          }
          if (!newData11[i - 1]) {
            newData11.push({ turnover: 0.0, month: i, year: data0[0].year });
          }

          for (j = 0; j < data12.length; j++) {
            if (i === data12[j].month) {
              newData12.push(data12[j]);
              this.totalAmountFacturizedOrdersPrevYearYTDValue += data12[j].turnover;
              this.ordersCountPrevYearYTDValue += data12[j].orders_count;
              break;
            }
          }
          if (!newData12[i - 1]) {
            newData12.push({ turnover: 0.0, orders_count: 0, month: i, year: data1[0].year });
          }
        }
      }

      this.ordersIntakeRelativeValue = (this.totalAmountOrdersIntakeSelectedYearValue / this.totalAmountOrdersIntakePrevYearYTDValue - 1) * 100;
      this.facturizedOrdersRelativeValue = (this.totalAmountFacturizedOrdersSelectedYearValue / this.totalAmountFacturizedOrdersPrevYearYTDValue - 1) * 100;
      this.ordersCountRelativeValue = (this.ordersCountSelectedYearValue / this.ordersCountPrevYearYTDValue - 1) * 100;

      const groupedOrdersIntake = [newData0, newData2];
      const groupedFacturizedOrders = [newData1, newData3];
      if (data.length === 17) {
        groupedOrdersIntake.push(newData11);
        groupedFacturizedOrders.push(newData12);
      }
      groupedFacturizedOrders.push(newData4FO);
      groupedOrdersIntake.push(newData4OI);

      const ordersIntakeChartDatasets = [];
      const facturizedOrdersChartDatasets = [];
      const facturizedOrdersCountChartDatasets = [];
      for (i = 0; i < groupedOrdersIntake.length; i++) {
        let label;
        if (i === 2) {
          label = `${groupedOrdersIntake[i][0].year} MTD`;
        } else if (i === 3) {
          label = `${groupedOrdersIntake[i][0].year} Plan Numbers`;
        } else if (i === 0) {
          label = groupedOrdersIntake[i][0].year;
        } else {
          label = groupedOrdersIntake[i][0].year;
        }
        ordersIntakeChartDatasets.push({
          name: label,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? label : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: groupedOrdersIntake[i].map(item => item.turnover),
        });
      }
      for (i = 0; i < groupedFacturizedOrders.length; i++) {
        let label;
        if (i === 2) {
          label = `${groupedFacturizedOrders[i][0].year} MTD`;
        } else if (i === 3) {
          label = `${groupedFacturizedOrders[i][0].year} Plan Numbers`;
        } else if (i === 0) {
          label = groupedOrdersIntake[i][0].year;
        } else {
          label = groupedFacturizedOrders[i][0].year;
        }
        facturizedOrdersChartDatasets.push({
          name: label,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? label : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: groupedFacturizedOrders[i].map(item => item.turnover),
        });
        if (groupedFacturizedOrders[i][0].orders_count !== undefined) {
          facturizedOrdersCountChartDatasets.push({
            name: label,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? label : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: groupedFacturizedOrders[i].map(item => item.orders_count),
          });
        }
      }

      this.ordersIntakeChart.series = ordersIntakeChartDatasets;
      this.facturizedOrdersChart.series = facturizedOrdersChartDatasets;
      this.facturizedOrdersCountChart.series = facturizedOrdersCountChartDatasets;

      let ordersIntakeTableDatasets = this.transformData(ordersIntakeChartDatasets);
      let facturizedOrdersTableDatasets = this.transformData(facturizedOrdersChartDatasets);
      const facturizedOrdersCountTableDatasets = this.transformData(facturizedOrdersCountChartDatasets);

      // Add new rows for cummulated differences
      ordersIntakeTableDatasets = this.addComparisonRows(ordersIntakeTableDatasets);
      facturizedOrdersTableDatasets = this.addComparisonRows(facturizedOrdersTableDatasets);

      // Table for orders intake
      this.ordersIntakeTable.fields = ['label', ...monthNames];
      this.ordersIntakeTable.items = ordersIntakeTableDatasets;
      this.ordersIntakeTable.totalRows = ordersIntakeTableDatasets.length;

      // Table for facturized orders
      this.facturizedOrdersTable.fields = ['label', ...monthNames];
      this.facturizedOrdersTable.items = facturizedOrdersTableDatasets;
      this.facturizedOrdersTable.totalRows = facturizedOrdersTableDatasets.length;

      // Table for facturized orders count
      this.facturizedOrdersCountTable.fields = ['label', ...monthNames];
      this.facturizedOrdersCountTable.items = facturizedOrdersCountTableDatasets;
      this.facturizedOrdersCountTable.totalRows = facturizedOrdersCountTableDatasets.length;

      // Top month
      this.ordersIntakeTopMonth = data[5].data.results[0];
      this.facturizedOrdersTopMonth = data[6].data.results[0];

      // Turnover by country groups
      const { year: year7, monthlyData: data7, ytdData: ytdData7 } = this.processDataOrdersByCountryGroup(data[7].data.results, 'turnover');
      const { year: year8, monthlyData: data8, ytdData: ytdData8 } = this.processDataOrdersByCountryGroup(data[8].data.results, 'turnover');
      const { year: year9, monthlyData: data9, ytdData: ytdData9 } = this.processDataOrdersByCountryGroup(data[9].data.results, 'turnover');
      const { year: year10, monthlyData: data10, ytdData: ytdData10 } = this.processDataOrdersByCountryGroup(data[10].data.results, 'turnover');
      const { year: year13, monthlyData: data13, ytdData: ytdData13 } = this.processDataOrdersByCountryGroup(data[13].data.results, 'turnover');
      const { year: year14, monthlyData: data14, ytdData: ytdData14 } = this.processDataOrdersByCountryGroup(data[14].data.results, 'turnover');

      // Tables for country groups
      let transformedArr1 = this.transformDataByCountryGroup(data[7].data.results, 'turnover');
      let transformedArr2 = this.transformDataByCountryGroup(data[8].data.results, 'turnover');

      // Combine and Sort Data
      let combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      let uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      let headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      let rows = countryGroups.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.ordersIntakeByCountryGroupTable.fields = headers;
      this.ordersIntakeByCountryGroupTable.items = rows;
      this.ordersIntakeByCountryGroupTable.totalRows = rows.length;

      transformedArr1 = this.transformDataByCountryGroup(data[9].data.results, 'turnover');
      transformedArr2 = this.transformDataByCountryGroup(data[10].data.results, 'turnover');

      // Combine and Sort Data
      combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate Rows
      rows = countryGroups.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.facturizedOrdersByCountryGroupTable.fields = headers;
      this.facturizedOrdersByCountryGroupTable.items = rows;
      this.facturizedOrdersByCountryGroupTable.totalRows = rows.length;

      const ordersIntakeByCountryGroupPrevYearDatasets = [];
      Object.keys(data7).forEach((country_group, i) => {
        ordersIntakeByCountryGroupPrevYearDatasets.push({
          name: `${country_group}-${year7}`,
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year7 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data7[country_group],
        });
      });

      const ordersIntakeByCountryGroupPrevYearMTDDatasets = [];
      Object.keys(data13).forEach((country_group, i) => {
        ordersIntakeByCountryGroupPrevYearMTDDatasets.push({
          name: `${country_group}-${year13} MTD`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${year13} MTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data13[country_group],
        });
      });

      const ordersIntakeByCountryGroupThisYearDatasets = [];
      Object.keys(data8).forEach((country_group, i) => {
        ordersIntakeByCountryGroupThisYearDatasets.push({
          name: `${country_group}-${year8}`,
          type: 'bar',
          stack: 'Ad3',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year8 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data8[country_group],
        });
      });

      const facturizedOrdersByCountryGroupPrevYearDatasets = [];
      Object.keys(data9).forEach((country_group, i) => {
        facturizedOrdersByCountryGroupPrevYearDatasets.push({
          name: `${country_group}-${year9}`,
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year9 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data9[country_group],
        });
      });

      const facturizedOrdersByCountryGroupPrevYearMTDDatasets = [];
      Object.keys(data14).forEach((country_group, i) => {
        facturizedOrdersByCountryGroupPrevYearMTDDatasets.push({
          name: `${country_group}-${year14} MTD`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${year14} MTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data14[country_group],
        });
      });

      const facturizedOrdersByCountryGroupThisYearDatasets = [];
      Object.keys(data10).forEach((country_group, i) => {
        facturizedOrdersByCountryGroupThisYearDatasets.push({
          name: `${country_group}-${year10}`,
          type: 'bar',
          stack: 'Ad3',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year10 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data10[country_group],
        });
      });

      this.ordersIntakeByCountryGroupChart.series = [...ordersIntakeByCountryGroupPrevYearDatasets, ...ordersIntakeByCountryGroupThisYearDatasets, ...ordersIntakeByCountryGroupPrevYearMTDDatasets];
      this.facturizedOrdersByCountryGroupChart.series = [...facturizedOrdersByCountryGroupPrevYearDatasets, ...facturizedOrdersByCountryGroupThisYearDatasets, ...facturizedOrdersByCountryGroupPrevYearMTDDatasets];

      // Orders intake by years
      this.ordersIntakeByYearsChart.xAxis.data = data[15].data.results.map(item => item.year);
      this.ordersIntakeByYearsChart.series = {
        name: "Turnover",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[15].data.results.map(item => item.turnover),
      };

      // Facturized orders by years
      this.facturizedOrdersByYearsChart.xAxis.data = data[16].data.results.map(item => item.year);
      this.facturizedOrdersByYearsChart.series = {
        name: "Turnover",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[16].data.results.map(item => item.turnover),
      };
    },
    updateOffersByMonth(data) {
      const newData0 = [];
      const newData1 = [];
      const newData4 = [];

      const data0 = data[0].data.results;
      const data1 = data[1].data.results;
      const data4 = data[4].data.results;

      for (var i = 1; i < 13; i++) {
        for (var j = 0; j < data0.length; j++) {
          if (i === data0[j].month) {
            newData0.push(data0[j]);
            if (data0[j].month < currentMonthNumber) {
              this.offersAmountPrevYearYTDValue += data0[j].offers_value;
              this.offersCountPrevYearYTDValue += data0[j].offers_count;
            }
            this.offersAmountPrevYearValue += data0[j].offers_value;
            this.offersCountPrevYearValue += data0[j].offers_count;
            break;
          }
        }
        if (!newData0[i - 1]) {
          newData0.push({ offers_value: 0.0, offers_count: 0, month: i, year: data0[0].year });
        }

        for (j = 0; j < data1.length; j++) {
          if (i === data1[j].month) {
            newData1.push(data1[j]);
            this.offersAmountSelectedYearValue += data1[j].offers_value;
            this.offersCountSelectedYearValue += data1[j].offers_count;
            break;
          }
        }
        if (!newData1[i - 1]) {
          newData1.push({ offers_value: 0.0, offers_count: 0, month: i, year: data1[0].year });
        }

        if (data.length === 5) {
          for (j = 0; j < data4.length; j++) {
            if (i === data4[j].month) {
              newData4.push(data4[j]);
              this.offersAmountPrevYearYTDValue += data4[j].offers_value;
              this.offersCountPrevYearYTDValue += data4[j].offers_count;
              break;
            }
          }
          if (!newData4[i - 1]) {
            newData4.push({ offers_value: 0.0, offers_count: 0, month: i, year: data0[0].year });
          }
        }
      }

      this.offersAmountRelativeValue = (this.offersAmountSelectedYearValue / this.offersAmountPrevYearYTDValue - 1) * 100;
      this.offersCountRelativeValue = (this.offersCountSelectedYearValue / this.offersCountPrevYearYTDValue - 1) * 100;

      const groupedOffers = [newData0, newData1];
      if (data.length === 5) {
        groupedOffers.push(newData4);
      }
      const offersAmountChartDatasets = [];
      for (i = 0; i < groupedOffers.length; i++) {
        let label;
        if (i === 2) {
          label = `${groupedOffers[i][0].year} MTD`;
        } else if (i === 0) {
          label = groupedOffers[i][0].year;
        } else {
          label = groupedOffers[i][0].year;
        }
        offersAmountChartDatasets.push({
          name: label,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? label : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: groupedOffers[i].map(item => item.offers_value),
        });
      }

      this.offersAmountChart.series = offersAmountChartDatasets;

      const offersAmountTableDatasets = this.transformData(offersAmountChartDatasets);

      // Table for offers amount
      this.offersAmountTable.fields = ['label', ...monthNames];
      this.offersAmountTable.items = offersAmountTableDatasets;
      this.offersAmountTable.totalRows = offersAmountTableDatasets.length;

      // Turnover by country groups
      const { year: year2, monthlyData: data2, ytdData: ytdData2 } = this.processDataOrdersByCountryGroup(data[2].data.results, 'offers_value');
      const { year: year3, monthlyData: data3, ytdData: ytdData3 } = this.processDataOrdersByCountryGroup(data[3].data.results, 'offers_value');

      const offersAmountByCountryGroupPrevYearDatasets = [];
      Object.keys(data2).forEach((country_group, i) => {
        offersAmountByCountryGroupPrevYearDatasets.push({
          name: `${country_group}-${year2}`,
          type: 'bar',
          stack: 'Ad1',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year2 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data2[country_group],
        });
      });

      const offersAmountByCountryGroupThisYearDatasets = [];
      Object.keys(data3).forEach((country_group, i) => {
        offersAmountByCountryGroupThisYearDatasets.push({
          name: `${country_group}-${year3}`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year3 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data3[country_group],
        });
      });

      this.offersAmountByCountryGroupChart.series = [...offersAmountByCountryGroupPrevYearDatasets, ...offersAmountByCountryGroupThisYearDatasets];

      // Table for offers amount by country group
      let transformedArr1 = this.transformDataByCountryGroup(data[2].data.results, 'offers_value');
      let transformedArr2 = this.transformDataByCountryGroup(data[3].data.results, 'offers_value');

      // Combine and Sort Data
      let combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      let uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      let headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      let rows = countryGroups.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.offersAmountByCountryGroupTable.fields = headers;
      this.offersAmountByCountryGroupTable.items = rows;
      this.offersAmountByCountryGroupTable.totalRows = rows.length;

      // Offers count by country groups
      const { year: year2c, monthlyData: data2c, ytdData: ytdData2c } = this.processDataOrdersByCountryGroup(data[2].data.results, 'offers_count');
      const { year: year3c, monthlyData: data3c, ytdData: ytdData3c } = this.processDataOrdersByCountryGroup(data[3].data.results, 'offers_count');

      const offersCountByCountryGroupPrevYearDatasets = [];
      Object.keys(data2c).forEach((country_group, i) => {
        offersCountByCountryGroupPrevYearDatasets.push({
          name: `${country_group}-${year2c}`,
          type: 'bar',
          stack: 'Ad1',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year2c : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data2c[country_group],
        });
      });

      const offersCountByCountryGroupThisYearDatasets = [];
      Object.keys(data3c).forEach((country_group, i) => {
        offersCountByCountryGroupThisYearDatasets.push({
          name: `${country_group}-${year3c}`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year3c : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data3c[country_group],
        });
      });

      this.offersCountByCountryGroupChart.series = [...offersCountByCountryGroupPrevYearDatasets, ...offersCountByCountryGroupThisYearDatasets];

      // Table for offers count by country group
      transformedArr1 = this.transformDataByCountryGroup(data[2].data.results, 'offers_count');
      transformedArr2 = this.transformDataByCountryGroup(data[3].data.results, 'offers_count');

      // Combine and Sort Data
      combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      rows = countryGroups.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.offersCountByCountryGroupTable.fields = headers;
      this.offersCountByCountryGroupTable.items = rows;
      this.offersCountByCountryGroupTable.totalRows = rows.length;
    },
    displayRelativeValue(prevYearYTDVal, thisYearVal) {
      return (thisYearVal / prevYearYTDVal - 1) * 100;
    },
    processDataOrdersByPlatform(data, value) {
      const year = data[0].year;
      const months = Array.from({ length: 12 }, (_, i) => i + 1);
      const platforms = [...new Set(data.map(item => item.platform))];
      const monthlyData = {};
      const ytdData = {};

      platforms.sort((a, b) => a.localeCompare(b));

      platforms.forEach(platform => {
        monthlyData[platform] = months.map(month =>
          data
            .filter(item => item.year === year && item.month === month && item.platform === platform)
            .reduce((sum, current) => sum + current[value], 0)
        );
        ytdData[platform] = monthlyData[platform].slice(0, currentMonthNumber).reduce((sum, current) => sum + current, 0);
      });

      return { year, monthlyData, ytdData };
    },
    updateOrdersByPlatformMonth(data) {
      const currMonthIndex = new Date().getMonth();

      const { year: year6, monthlyData: data6, ytdData: ytdData6 } = this.processDataOrdersByPlatform(data[2].data.results, 'turnover');
      const { year: year7, monthlyData: data7, ytdData: ytdData7 } = this.processDataOrdersByPlatform(data[2].data.results, 'orders_count');
      const { year: year8, monthlyData: data8, ytdData: ytdData8 } = this.processDataOrdersByPlatform(data[3].data.results, 'turnover');
      const { year: year9, monthlyData: data9, ytdData: ytdData9 } = this.processDataOrdersByPlatform(data[3].data.results, 'orders_count');
      const { year: year10, monthlyData: data10, ytdData: ytdData10 } = this.processDataOrdersByPlatform(data[5].data.results, 'turnover');
      const { year: year11, monthlyData: data11, ytdData: ytdData11 } = this.processDataOrdersByPlatform(data[5].data.results, 'orders_count');

      const { year: year0, monthlyData: data0, ytdData: ytdData0 } = this.processDataOrdersByPlatform(data[0].data.results, 'turnover');
      const { year: year1, monthlyData: data1, ytdData: ytdData1 } = this.processDataOrdersByPlatform(data[1].data.results, 'turnover');
      const { year: year2, monthlyData: data2, ytdData: ytdData2 } = this.processDataOrdersByPlatform(data[0].data.results, 'orders_count');
      const { year: year3, monthlyData: data3, ytdData: ytdData3 } = this.processDataOrdersByPlatform(data[1].data.results, 'orders_count');
      const { year: year4, monthlyData: data4, ytdData: ytdData4 } = this.processDataOrdersByPlatform(data[4].data.results, 'turnover');
      const { year: year5, monthlyData: data5, ytdData: ytdData5 } = this.processDataOrdersByPlatform(data[4].data.results, 'orders_count');

      const ordersIntakeByPlatformAmountPrevYearDatasets = [];
      Object.keys(data6).forEach((platform, i) => {
        ordersIntakeByPlatformAmountPrevYearDatasets.push({
          name: `${platform}-${year6}`,
          type: 'bar',
          stack: 'Ad1',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year6 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data6[platform],
        });
        const totalSum = data6[platform].reduce((sum, value) => sum + value, 0);
        this.totalOrdersIntakeAmountsByPlatformPrevYear[platform] = totalSum;
      });
      this.totalOrdersIntakeAmountsByPlatformYTDValue = ytdData10;

      const ordersIntakeByPlatformAmountPrevYearMTDDatasets = [];
      Object.keys(data10).forEach((platform, i) => {
        ordersIntakeByPlatformAmountPrevYearMTDDatasets.push({
          name: `${platform}-${year10} MTD`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${year10} MTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data10[platform].map((value, index) => index === currMonthIndex ? value : 0),
        });
      });

      const ordersIntakeByPlatformAmountThisYearDatasets = [];
      Object.keys(data8).forEach((platform, i) => {
        ordersIntakeByPlatformAmountThisYearDatasets.push({
          name: `${platform}-${year8}`,
          type: 'bar',
          stack: 'Ad3',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year8 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data8[platform],
        });
        const totalSum = data8[platform].reduce((sum, value) => sum + value, 0);
        this.totalOrdersIntakeAmountsByPlatformThisYear[platform] = totalSum;
      });

      const ordersIntakeByPlatformCountPrevYearDatasets = [];
      Object.keys(data7).forEach((platform, i) => {
        ordersIntakeByPlatformCountPrevYearDatasets.push({
          name: `${platform}-${year7}`,
          type: 'bar',
          stack: 'Ad1',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year7 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data7[platform],
        });
        const totalSum = data7[platform].reduce((sum, value) => sum + value, 0);
        this.totalOrdersIntakeCountsByPlatformPrevYear[platform] = totalSum;
      });
      this.totalOrdersIntakeCountsByPlatformYTDValue = ytdData11;

      const ordersIntakeByPlatformCountPrevYearMTDDatasets = [];
      Object.keys(data11).forEach((platform, i) => {
        ordersIntakeByPlatformCountPrevYearMTDDatasets.push({
          name: `${platform}-${year11} MTD`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${year11} MTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data11[platform].map((value, index) => index === currMonthIndex ? value : 0),
        });
      });

      const ordersIntakeByPlatformCountThisYearDatasets = [];
      Object.keys(data9).forEach((platform, i) => {
        ordersIntakeByPlatformCountThisYearDatasets.push({
          name: `${platform}-${year9}`,
          type: 'bar',
          stack: 'Ad3',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year9 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data9[platform],
        });
        const totalSum = data9[platform].reduce((sum, value) => sum + value, 0);
        this.totalOrdersIntakeCountsByPlatformThisYear[platform] = totalSum;
      });

      const ordersByPlatformAmountPrevYearDatasets = [];
      Object.keys(data0).forEach((platform, i) => {
        ordersByPlatformAmountPrevYearDatasets.push({
          name: `${platform}-${year0}`,
          type: 'bar',
          stack: 'Ad1',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year0 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data0[platform],
        });
        const totalSum = data0[platform].reduce((sum, value) => sum + value, 0);
        this.totalAmountsByPlatformPrevYear[platform] = totalSum;
      });
      this.totalAmountsByPlatformYTDValue = ytdData4;

      const ordersByPlatformAmountPrevYearMTDDatasets = [];
      Object.keys(data4).forEach((platform, i) => {
        ordersByPlatformAmountPrevYearMTDDatasets.push({
          name: `${platform}-${year4} MTD`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${year4} MTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data4[platform].map((value, index) => index === currMonthIndex ? value : 0),
        });
      });

      const ordersByPlatformAmountThisYearDatasets = [];
      Object.keys(data1).forEach((platform, i) => {
        ordersByPlatformAmountThisYearDatasets.push({
          name: `${platform}-${year1}`,
          type: 'bar',
          stack: 'Ad3',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year1 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data1[platform],
        });
        const totalSum = data1[platform].reduce((sum, value) => sum + value, 0);
        this.totalAmountsByPlatformThisYear[platform] = totalSum;
      });

      const ordersByPlatformCountPrevYearDatasets = [];
      Object.keys(data2).forEach((platform, i) => {
        ordersByPlatformCountPrevYearDatasets.push({
          name: `${platform}-${year2}`,
          type: 'bar',
          stack: 'Ad1',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year2 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data2[platform],
        });
        const totalSum = data2[platform].reduce((sum, value) => sum + value, 0);
        this.totalCountsByPlatformPrevYear[platform] = totalSum;
      });
      this.totalCountsByPlatformYTDValue = ytdData5;

      const ordersByPlatformCountPrevYearMTDDatasets = [];
      Object.keys(data5).forEach((platform, i) => {
        ordersByPlatformCountPrevYearMTDDatasets.push({
          name: `${platform}-${year5} MTD`,
          type: 'bar',
          stack: 'Ad2',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${year5} MTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data5[platform].map((value, index) => index === currMonthIndex ? value : 0),
        });
      });

      const ordersByPlatformCountThisYearDatasets = [];
      Object.keys(data3).forEach((platform, i) => {
        ordersByPlatformCountThisYearDatasets.push({
          name: `${platform}-${year3}`,
          type: 'bar',
          stack: 'Ad3',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? year3 : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: data3[platform],
        });
        const totalSum = data3[platform].reduce((sum, value) => sum + value, 0);
        this.totalCountsByPlatformThisYear[platform] = totalSum;
      });

      this.ordersIntakeByPlatformAmountChart.series = [...ordersIntakeByPlatformAmountPrevYearDatasets, ...ordersIntakeByPlatformAmountThisYearDatasets, ...ordersIntakeByPlatformAmountPrevYearMTDDatasets];
      this.ordersIntakeByPlatformCountChart.series = [...ordersIntakeByPlatformCountPrevYearDatasets, ...ordersIntakeByPlatformCountThisYearDatasets, ...ordersIntakeByPlatformCountPrevYearMTDDatasets];

      this.ordersByPlatformAmountChart.series = [...ordersByPlatformAmountPrevYearDatasets, ...ordersByPlatformAmountThisYearDatasets, ...ordersByPlatformAmountPrevYearMTDDatasets];
      this.ordersByPlatformCountChart.series = [...ordersByPlatformCountPrevYearDatasets, ...ordersByPlatformCountThisYearDatasets, ...ordersByPlatformCountPrevYearMTDDatasets];

      // Table for Orders intake by platform amount
      let transformedArr1 = this.transformDataByPlatform(data[2].data.results, 'turnover');
      let transformedArr2 = this.transformDataByPlatform(data[3].data.results, 'turnover');

      // Combine and Sort Data
      let combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      let uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      let headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      let rows = platforms.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.ordersIntakeByPlatformAmountTable.fields = headers;
      this.ordersIntakeByPlatformAmountTable.items = rows;
      this.ordersIntakeByPlatformAmountTable.totalRows = rows.length;

      // Table for Orders intake by platform count
      transformedArr1 = this.transformDataByPlatform(data[2].data.results, 'orders_count');
      transformedArr2 = this.transformDataByPlatform(data[3].data.results, 'orders_count');

      // Combine and Sort Data
      combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      rows = platforms.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.ordersIntakeByPlatformCountTable.fields = headers;
      this.ordersIntakeByPlatformCountTable.items = rows;
      this.ordersIntakeByPlatformCountTable.totalRows = rows.length;

      // Table for orders by platform count
      transformedArr1 = this.transformDataByPlatform(data[0].data.results, 'turnover');
      transformedArr2 = this.transformDataByPlatform(data[1].data.results, 'turnover');

      // Combine and Sort Data
      combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      rows = platforms.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.ordersByPlatformAmountTable.fields = headers;
      this.ordersByPlatformAmountTable.items = rows;
      this.ordersByPlatformAmountTable.totalRows = rows.length;

      // Table for orders by platform count
      transformedArr1 = this.transformDataByPlatform(data[0].data.results, 'orders_count');
      transformedArr2 = this.transformDataByPlatform(data[1].data.results, 'orders_count');

      // Combine and Sort Data
      combinedData = [...transformedArr1, ...transformedArr2]
        .sort((a, b) => {
          const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
          if (aYear === bYear) {
            return aMonth - bMonth;
          }
          return aYear - bYear;
        });

      // Generate and Sort Headers
      uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
        const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
        if (aMonth === bMonth) {
          return aYear - bYear;
        }
        return aMonth - bMonth;
      });

      headers = ['Label', ...uniqueHeaders];

      // Generate Rows
      rows = platforms.map(group => {
        const row = { 'Label': group };
        uniqueHeaders.forEach(header => {
          const data = combinedData.find(item => item.key === header);
          row[header] = data ? data[group] : '0';
        });
        return row;
      });

      this.ordersByPlatformCountTable.fields = headers;
      this.ordersByPlatformCountTable.items = rows;
      this.ordersByPlatformCountTable.totalRows = rows.length;
    },
    updateOpenOrders(data) {
      const openOrdersAmounts = data[0].data.results.map(item => item.value);
      const openOrdersPaidAmounts = data[0].data.results.map(item => item.payment);
      const openOrdersCount = data[0].data.results.map(item => item.count);
      if (openOrdersAmounts[0] !== undefined) {
        this.totalAmountOpenOrders = openOrdersAmounts.reduce((a, b) => (a + b));
      }
      else {
        this.totalAmountOpenOrders = 0.0;
      }
      if (openOrdersPaidAmounts[0] !== undefined) {
        this.totalAmountPaidOpenOrders = openOrdersPaidAmounts.reduce((a, b) => (a + b));
      }
      else {
        this.totalAmountPaidOpenOrders = 0.0;
      }
      this.totalAmountOpenOrdersTitle = `open orders (${openOrdersCount.reduce((a, b) => (a + b))} orders)`;

      const overdueOrdersAmounts = data[1].data.results.map(item => item.value);
      const notPaidInvoicesAmounts = data[2].data.results.map(item => item.value);

      this.openOrdersChart.xAxis.data = data[0].data.results.map(item => item.month_year);
      this.openOrdersChart.series[0] = {
        name: "Amount",
        type: 'line',
        areaStyle: {},
        data: openOrdersAmounts,
      };
      this.openOrdersChart.series[1] = {
        name: "Amount Paid",
        type: 'line',
        areaStyle: {},
        data: openOrdersPaidAmounts,
      };
      this.openOrdersChart.series[2] = {
        name: "Amount Overdue",
        type: 'line',
        areaStyle: {},
        data: overdueOrdersAmounts,
      };

      this.totalAmountOverdueOrders = overdueOrdersAmounts.reduce((a, b) => (a + b));
      if (notPaidInvoicesAmounts[0] !== undefined) {
        this.totalAmountNotPaidInvoices = notPaidInvoicesAmounts.reduce((a, b) => (a + b));
      }
      else {
        this.totalAmountNotPaidInvoices = 0.0;
      }

      this.openOrdersAmountsChart.xAxis.data = data[3].data.map(item => `${new Date(item.created).getMonth() + 1}.${new Date(item.created).getFullYear()}`);
      this.openOrdersAmountsChart.series = {
        name: "Amount",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          distance: 5,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: data[3].data.map(item => item.amount),
      };

      // Table for open orders amount development
      const openOrdersAmountsTableDatasets = this.transformOpenOrdersData(data[3].data);
      this.openOrdersAmountsTable.fields = ['label', ...monthNames];
      this.openOrdersAmountsTable.items = openOrdersAmountsTableDatasets;
      this.openOrdersAmountsTable.totalRows = openOrdersAmountsTableDatasets.length;

      this.totalAmountExpIn = data[4].data.results[0].total_amount;
      this.totalLiquidity = (this.totalAmountExpIn - this.totalAmountNotPaidInvoices);

      // Open orders by platform
      this.openOrdersPlatformChart.series[0].data = data[5].data.results.map(item => {
        return {
          value: item.value,
          name: item.platform,
        };
      });

      // Open orders by country
      this.openOrdersCountryChart.series[0].data = data[6].data.results.map(item => {
        return {
          value: item.value,
          name: item.country,
        };
      });
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getOrdersIntakeAndFacturizedOrders() {
      this.showOrdersIntakeAndFacturizedOrders = true;

      try {
        const { qParamsSelectedYear, qParamsPrevYear } = this.getFromToDates();

        const requests = [
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/plan-numbers-current-year/`, {}),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/top-month-orders-intake/`, {}),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/top-month-facturized-orders/`, {}),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-country-group/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-country-group/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, qParamsSelectedYear),
        ];

        if (currentDate.getFullYear() === new Date().getFullYear() && currentDate.getMonth() === new Date().getMonth()) {
          let previousYear = currentDate.getFullYear() - 1;
          let previousMonth = currentDate.getMonth();
          let previousDay = currentDate.getDate();
          // Check if the current date is Feb 29 and the previous year is not a leap year
          if (previousMonth === 1 && day === 29 && !(previousYear % 4 === 0 && (previousYear % 100 !== 0 || previousYear % 400 === 0))) {
            previousDay = 28; // Adjust day for non-leap year
          }
          const qParamsPrevYearMonthToDate = {
            from_date: `${previousYear}-${previousMonth + 1}-1`,
            to_date: `${previousYear}-${previousMonth + 1}-${previousDay}`,
          };

          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-country-group/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, qParamsPrevYearMonthToDate));
        }

        // Data by years
        requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-year/`, {}));
        requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-year/`, {}));

        const response = await axios.all(requests);
        this.updateOrdersIntakeAndFacturizedOrders(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrdersIntakeAndFacturizedOrders = false;
      }
    },
    async getOffersByMonth() {
      this.showOffersByMonth = true;

      try {
        const { qParamsSelectedYear, qParamsPrevYear } = this.getFromToDates();

        const requests = [
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-month/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-month/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-country-group-and-month/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-country-group-and-month/`, qParamsSelectedYear),
        ];

        if (currentDate.getFullYear() === new Date().getFullYear() && currentDate.getMonth() === new Date().getMonth()) {
          let previousYear = currentDate.getFullYear() - 1;
          let previousMonth = currentDate.getMonth();
          let previousDay = currentDate.getDate();
          // Check if the current date is Feb 29 and the previous year is not a leap year
          if (previousMonth === 1 && day === 29 && !(previousYear % 4 === 0 && (previousYear % 100 !== 0 || previousYear % 400 === 0))) {
            previousDay = 28; // Adjust day for non-leap year
          }
          const qParamsPrevYearMonthToDate = {
            from_date: `${previousYear}-${previousMonth + 1}-1`,
            to_date: `${previousYear}-${previousMonth + 1}-${previousDay}`,
          };

          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-month/`, qParamsPrevYearMonthToDate));
        }

        const response = await axios.all(requests);
        this.updateOffersByMonth(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOffersByMonth = false;
      }
    },
    async getOrdersByPlatformMonth() {
      this.showOrdersByPlatformMonth = true;

      try {
        const { qParamsSelectedYear, qParamsPrevYear } = this.getFromToDates();

        const requests = [
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-platform-and-month/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-platform-and-month/`, qParamsSelectedYear),
        ];

        if (currentDate.getFullYear() === new Date().getFullYear() && currentDate.getMonth() === new Date().getMonth()) {
          let previousYear = currentDate.getFullYear() - 1;
          let previousMonth = currentDate.getMonth();
          let previousDay = currentDate.getDate();
          // Check if the current date is Feb 29 and the previous year is not a leap year
          if (previousMonth === 1 && day === 29 && !(previousYear % 4 === 0 && (previousYear % 100 !== 0 || previousYear % 400 === 0))) {
            previousDay = 28; // Adjust day for non-leap year
          }
          const qParamsPrevYearMonthToDate = {
            from_date: `${previousYear}-1-1`,
            to_date: `${previousYear}-${previousMonth + 1}-${previousDay}`,
          };

          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-platform-and-month/`, qParamsPrevYearMonthToDate));
        }

        const response = await axios.all(requests);
        this.updateOrdersByPlatformMonth(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrdersByPlatformMonth = false;
      }
    },
    async getOpenOrders() {
      this.showOpenOrdersAnalytics = true;

      try {
        const now = new Date();
        const next90DaysDate = new Date(now.setDate(now.getDate() + 90));

        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-orders-by-date/`, this.queryParamsOpenOrders),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/overdue-orders-by-date/`, this.queryParamsOpenOrders),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/not-paid-invoices-by-date/`, {
            from_date: `${new Date().getFullYear()}-1-1`,
            to_date: `${now.getFullYear()}-${next90DaysDate.getMonth() + 1}-${next90DaysDate.getDate()}`
          }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-orders-amounts/`, this.queryParamsOpenOrders),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/total-exp-in-payments/`, this.queryParamsOpenOrders),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-orders-by-platform/`, this.queryParamsOpenOrders),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-orders-by-country/`, this.queryParamsOpenOrders),
        ]);
        this.updateOpenOrders(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOpenOrdersAnalytics = false;
      }
    },
    async exportOpenOrders() {
      this.showOpenOrdersDwnIcon = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-orders/`, this.queryParamsOpenOrders);
        this.exportCSV(response.data.results, 'OpenOrders.csv');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOpenOrdersDwnIcon = false;
      }
    },
    async exportPaidOpenOrders() {
      this.showPaidOpenOrdersDwnIcon = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/paid-open-orders/`, this.queryParamsOpenOrders);
        this.exportCSV(response.data.results, 'PaidOpenOrders.csv');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPaidOpenOrdersDwnIcon = false;
      }
    },
    async exportOverdueOrders() {
      this.showOverdueOrdersDwnIcon = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/overdue-orders/`, this.queryParamsOpenOrders);
        this.exportCSV(response.data.results, 'OverdueOrders.csv');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOverdueOrdersDwnIcon = false;
      }
    },
    async exportNotPaidInvoices() {
      this.showNotPaidInvoicesDwnIcon = true;
      try {
        const now = new Date();
        const next90DaysDate = new Date(now.setDate(now.getDate() + 90));
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/not-paid-invoices/`, {
          from_date: `${new Date().getFullYear()}-1-1`,
          to_date: `${now.getFullYear()}-${next90DaysDate.getMonth() + 1}-${next90DaysDate.getDate()}`
        });
        this.exportCSV(response.data.results, 'NotPaidInvoices.csv');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotPaidInvoicesDwnIcon = false;
      }
    },
  },
}
</script>

<style lang="scss"></style>